import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Card } from "react-bootstrap";
import PartnersGlobal from "../components/PartnersGlobal";
import ContactUsGlobal from "../components/ContactUsGlobal";

const Solutions = () => {
    return (
        <>
            <Helmet>
                <title>Grey Solutions | Solutions</title>
                <meta name="description" content="Grey Solutions | Advanced solutions for a safer tomorrow" />
                <meta property="og:title" content="Grey Solutions | Contact" />
                <meta property="og:description" content="Grey Solutions is a leading defense technology company specializing in innovative solutions. With a focus on collaboration, customer satisfaction, and sustainability, we offer a wide range of advanced systems, including weapons, UAVs, cybersecurity, and communication equipment. Our goal is to enhance military capabilities and provide a competitive edge in the global security landscape while maintaining ethical and responsible operations." />
                <meta property="og:image" itemprop="image" content="https://greysolutions.my/static/media/GS-logo.3ddecfd01f51aa7d379334435510e548.svg" />
                <meta property="og:url" content="https://greysolutions.my/contact" />
            </Helmet>

            <section className="d-flex align-items-center justify-content-start cover-container w-100 solutions-hero-banner vh-100 section-start">
                <Container className="text-container w-100">
                    <div className="text-wrapper">
                        <p className="text-light text-uppercase mb-4 copy">
                            Advanced solutions for a safer tomorrow
                        </p>
                        <h2 className="text-light text-uppercase main-title mb-4">
                            Our solutions
                        </h2>
                        <h4 className="text-light mb-4 sub-title fw-normal">
                            “We adhere to the highest ethical standards in all our dealings.
                            Integrity, transparency, and accountability guide our actions”
                        </h4>
                    </div>
                </Container>
            </section>

            <section className="solutions cover-container w-100" id="solutions">
                <Container>
                    <Row>
                        <Col xs={12} className="mb-4">
                            <div className="solutions-box naval">
                                <div className="text-wrap">
                                    <h2 className="sub-title text-uppercase text-light fw-bolder">
                                        NAVAL & SUBMARINE
                                    </h2>
                                    <ul className="text-light ps-3">
                                        <li>Radio Communication</li>
                                        <li>Manned Underwater Vehicle</li>
                                        <li>Surface and Sub-Surface Unmanned Vehicle</li>
                                        <li>Acoustic Detection and Positioning System</li>
                                        <li>Buoyancy</li>
                                        <li>Search & Rescue</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} lg={6} className="mb-4">
                            <div className="solutions-box portableweapons">
                                <div className="text-wrap">
                                    <h2 className="sub-title text-uppercase text-light fw-bolder">
                                        PORTABLE WEAPONS & AMMUNITION
                                    </h2>
                                    <ul className="text-light ps-3">
                                        <li>Pistols & Sub Machine Guns</li>
                                        <li>Carbines, Rifles and Grenade Launchers</li>
                                        <li>Machine Guns</li>
                                        <li>Sighting and Aiming Solutions</li>
                                        <li>Small Arms Management Solutions</li>
                                        <li>Training Systems</li>
                                        <li>Small, Medium and & Large Calibre Ammunitions</li>
                                        <li>Accessories</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} lg={6} className="mb-4">
                            <div className="solutions-box small-box integratedweapons">
                                <div className="text-wrap">
                                    <h2 className="sub-title text-uppercase text-light fw-bolder">
                                        INTEGRATED WEAPONS SOLUTIONS
                                    </h2>
                                    <ul className="text-light ps-3">
                                        <li>Machine Guns</li>
                                        <li>Airborne Crew Served Weapon Solutions</li>
                                        <li>Airborne Fixed Forward-Firing Solutions</li>
                                        <li>Land & Sea Mounted Weapon Solutions</li>
                                        <li>Remote Weapon Stations</li>
                                        <li>360-Degree Service</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} lg={6} className="mb-4">
                            <div className="solutions-box small-box lifeextension">
                                <div className="text-wrap">
                                    <h2 className="sub-title text-uppercase text-light fw-bolder">
                                        NON-LETHAL WEAPONS & PYROTECHNICS
                                    </h2>
                                    <ul className="text-light ps-3">
                                        <li>Anti Riot Launchers</li>
                                        <li>Grenades (All Types)</li>
                                        <li>Self Protection Systems</li>
                                        <li>Decoy</li>
                                        <li>Accessories and Supplementary Products</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} lg={6} className="mb-4">
                            <div className="solutions-box comunication">
                                <div className="text-wrap">
                                    <h2 className="sub-title text-uppercase text-light fw-bolder">
                                        COMMUNICATION
                                    </h2>
                                    <ul className="text-light ps-3">
                                        <li>High-frequency and land mobile radio tactical communications</li>
                                        <li>Secure and tactical radio communications and satellite navigation systems</li>
                                        <li>Combat net radios and accessories</li>
                                        <li>Military neck microphones, handsets, headsets, and speakers</li>
                                        <li>Army hearing protection and communication systems</li>
                                        <li>Critical infrastructure networks and communication solutions</li>
                                        <li>Military broadband, radio frequency (RF) filters, receivers, and threat detectors</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} lg={6} className="mb-4">
                            <div className="solutions-box eod">
                                <div className="text-wrap">
                                    <h2 className="sub-title text-uppercase text-light fw-bolder">
                                        EOD/C-IED
                                    </h2>
                                    <ul className="text-light ps-3">
                                        <li>EOD Distruptors</li>
                                        <li>EOD Robots</li>
                                        <li>EOD Tools</li>
                                        <li>EOD Suits</li>
                                        <li>Accessories and Supplementary Products</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} lg={6} className="mb-4">
                            <div className="solutions-box small-box ummannedautomated">
                                <div className="text-wrap">
                                    <h2 className="sub-title text-uppercase text-light fw-bolder">
                                        Unmanned Systems & Protection
                                    </h2>
                                    <ul className="text-light ps-3">
                                        <li>Unmanned Aerial Systems</li>
                                        <li>Unmanned Ground Systems</li>
                                        <li>Unmanned Maritime Systems</li>
                                        <li>Counter & Protection Systems</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} lg={6} className="mb-4">
                            <div className="solutions-box small-box lightweighthovercraft">
                                <div className="text-wrap">
                                    <h2 className="sub-title text-uppercase text-light fw-bolder">
                                        LIGHTWEIGHT HOVERCRAFT
                                    </h2>
                                    <ul className="text-light ps-3">
                                        <li>Rescue Hovercraft</li>
                                        <li>Military Hovercraft</li>
                                        <li>Commercial Hovercraft</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <PartnersGlobal />
            <ContactUsGlobal />
        </>
    );
};

export default Solutions;