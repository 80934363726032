import React from "react";
import { Container } from "react-bootstrap";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="mt-auto text-light bg-dark py-3">
      <Container>
        <p className="text-center mb-0">
          &copy; Copyright Grey Solutions Sdn. Bhd. {currentYear}. All rights reserved.
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
