// src/App.js
import React, { useEffect, useLayoutEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import feather from 'feather-icons';
import Home from './pages/Home';
import About from './pages/About';
import Solutions from './pages/Solutions';
import Contact from './pages/Contact';

const App = () => {

  useEffect(() => {

    const header = document.querySelector("header");
    const firstSection = document.querySelector("section");
    const secondSection = document.querySelectorAll("section")[1];
    const navbarToggle = document.querySelector(".navbar-toggler");
    const body = document.body;
    const navbarCollapse = document.querySelector(".navbar-collapse");
    const scrollLinks = document.querySelectorAll('a[href^="#"]');
    const footerYear = document.querySelector(".footer-year");

    // Set footer year dynamically
    if (footerYear) {
      footerYear.textContent = new Date().getFullYear();
    }

    // Add scroll handling logic for the header
    if (header && firstSection && secondSection) {
      const secondSectionTop = secondSection.offsetTop;

      // Function to update header state
      const updateHeaderState = () => {
        if (window.scrollY > secondSectionTop - 150) {
          header.classList.add("scrolled");
        } else {
          header.classList.remove("scrolled");
        }
      };

      // Function to toggle scroll behavior
      const toggleScroll = () => {
        if (navbarCollapse.classList.contains("show") && window.innerWidth <= 768) {
          body.classList.add("no-scroll");
        } else {
          body.classList.remove("no-scroll");
        }
      };

      updateHeaderState();

      // Attach event listeners
      window.addEventListener("scroll", updateHeaderState);
      navbarToggle.addEventListener("click", toggleScroll);
      navbarCollapse.addEventListener("shown.bs.collapse", toggleScroll);
      navbarCollapse.addEventListener("hidden.bs.collapse", toggleScroll);

      // Resize handler
      const resizeHandler = () => {
        if (window.innerWidth > 768 && navbarCollapse.classList.contains("show")) {
          navbarCollapse.classList.remove("show");
        }

        if (window.innerWidth > 768) {
          body.classList.remove("no-scroll");
        }
      };

      window.addEventListener("resize", resizeHandler);

      // Smooth scrolling on anchor clicks
      scrollLinks.forEach((link) => {
        link.addEventListener("click", function (e) {
          e.preventDefault();
          const targetId = this.getAttribute("href").substring(1);
          const targetElement = document.getElementById(targetId);

          if (targetElement) {
            window.scrollTo({
              top: targetElement.offsetTop - 95,
              behavior: "smooth",
            });
          }
        });
      });

      // Handle initial page load hash scrolling
      if (window.location.hash) {
        const targetId = window.location.hash.substring(1);
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
          window.scrollTo({
            top: targetElement.offsetTop - 95,
            behavior: "smooth",
          });
        }
      }

      // Cleanup function to remove event listeners
      return () => {
        window.removeEventListener("scroll", updateHeaderState);
        navbarToggle.removeEventListener("click", toggleScroll);
        navbarCollapse.removeEventListener("shown.bs.collapse", toggleScroll);
        navbarCollapse.removeEventListener("hidden.bs.collapse", toggleScroll);
        window.removeEventListener("resize", resizeHandler);
        scrollLinks.forEach((link) => link.removeEventListener("click"));
      };
    }
  }, []);

  useLayoutEffect(() => {
    feather.replace();
  }, []);

  return (
    <Router>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/Solutions" element={<Solutions />} />
          <Route path="/Contact" element={<Contact />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
};

export default App;
