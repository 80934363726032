import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import PartnersGlobal from "../components/PartnersGlobal";
import ContactUsGlobal from "../components/ContactUsGlobal";
import box01 from "../images/bg-wwa-box01.avif";
import box02 from "../images/bg-wwa-box02.avif";
import box03 from "../images/bg-wwa-box03.avif";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Grey Solutions | Homepage</title>
        <meta name="description" content="Grey Solutions | Advanced solutions for a safer tomorrow" />
        <meta property="og:title" content="Grey Solutions | Homepage" />
        <meta property="og:description" content="Grey Solutions is a leading defense technology company specializing in innovative solutions. With a focus on collaboration, customer satisfaction, and sustainability, we offer a wide range of advanced systems, including weapons, UAVs, cybersecurity, and communication equipment. Our goal is to enhance military capabilities and provide a competitive edge in the global security landscape while maintaining ethical and responsible operations." />
        <meta property="og:image" itemprop="image" content="https://greysolutions.my/static/media/GS-logo.3ddecfd01f51aa7d379334435510e548.svg" />
        <meta property="og:url" content="https://greysolutions.my" />
      </Helmet>

      <section className="vh-100 cover-container hero-banner section-start w-100">
        <Container className="text-container w-100">
          <h3 className="text-uppercase sub-title">Advanced solutions for a safer tomorrow</h3>
          <h2 className="text-uppercase main-title">
            Defending the Future
          </h2>
        </Container>
      </section>

      <section className="home-who-are-we text-bg-dark" id="whowarewe">
        <Container className="py-5">
          <h2 className="text-uppercase main-title mb-5 text-center">Who we are</h2>
          <Row className="justify-content-center text-container">
            {[{
              img: box01,
              text: "About The Company",
              link: "about#aboutthecompany"
            }, {
              img: box02,
              text: "SETTING THE STANDARDS",
              link: "about#standards"
            }, {
              img: box03,
              text: "Our Values",
              link: "about#value"
            }].map((item, index) => (
              <Col xs={12} lg={4} className="mb-4" key={index}>
                <Card className="card-gs-custom shadow">
                  <Card.Img variant="top" alt={item.text} src={item.img} />
                  <Card.Body>
                    <Card.Text className="text-uppercase">{item.text}</Card.Text>
                    <Button variant="primary" href={item.link} className="stretched-link">
                      Read more <i className="icon" data-feather="arrow-right"></i>
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <PartnersGlobal />
      <ContactUsGlobal />
      </>
  );
}

export default Home;
