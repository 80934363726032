import React from "react";
import logo from "../images/GS-logo.svg";

const Header = () => {
  return (
    <header className="justify-content-center align-items-center d-flex fixed-top nav-pills navbar-expand-md px-3 py-3 text-bg-dark">
      <a className="logo-item navbar-brand" href="/">
        <img alt="Grey Solutions Sdn Bhd" className="logo" src={logo} title="Grey Solutions Sdn Bhd" />
      </a>
      <button
        className="navbar-toggler p-2"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#mobnav"
        aria-controls="mobnav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i className="icon" data-feather="menu"></i>
      </button>
      <div className="collapse navbar-collapse" id="mobnav">
        <ul className="justify-content-center align-items-center nav-pills nav">
          <li className="nav-item">
            <a className="nav-link" href="/about">
              About
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/solutions">
              Solutions
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/contact">
              Contact
            </a>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;