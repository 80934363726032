import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import axios from 'axios';

const ContactPage = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [status, setStatus] = useState(null);  // Manage form submission status
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    // Handle input field changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Set to true when the form is submitting
        setStatus(null); // Reset previous status

        try {
            // Example: Using axios for POST request
            const response = await axios.post('https://app.greysolutions.my/send-email', formData);

            if (response.status === 200) {
                setStatus("success");
                setFormData({ name: '', email: '', phone: '', message: '' });
            } else {
                setStatus("error");
            }
        } catch (error) {
            console.error(error);
            setStatus("error");
        } finally {
            setIsSubmitting(false); // Set to false when the request is done
        }
    };
    return (
        <>
            <Helmet>
                <title>Grey Solutions | Contact</title>
                <meta name="description" content="Grey Solutions | Advanced solutions for a safer tomorrow" />
                <meta property="og:title" content="Grey Solutions | Contact" />
                <meta property="og:description" content="Grey Solutions is a leading defense technology company specializing in innovative solutions. With a focus on collaboration, customer satisfaction, and sustainability, we offer a wide range of advanced systems, including weapons, UAVs, cybersecurity, and communication equipment. Our goal is to enhance military capabilities and provide a competitive edge in the global security landscape while maintaining ethical and responsible operations." />
                <meta property="og:image" itemprop="image" content="https://greysolutions.my/static/media/GS-logo.3ddecfd01f51aa7d379334435510e548.svg" />
                <meta property="og:url" content="https://greysolutions.my/contact" />
            </Helmet>
            <section className="contact-page d-flex flex-column section-start cover-container" id="contact">
                <Container>
                    <Row className="gap-3 gap-lg-0 justify-content-center">
                        <Col xs={12} xl={6}>
                            <h2 className="text-light text-uppercase main-title mb-md-5 mb-4">
                                Get in touch with us
                            </h2>
                            <h3 className="text-light text-uppercase fw-bolder mb-md-4 mb-3 sub-title">
                                GREY SOLUTIONS SDN. BHD. <span className="small">(1247852-P)</span>
                            </h3>
                            <p className="text-light mb-4 copy">
                                Unit A-16-09, Block A, Tropicana Avenue,
                                <br />
                                No. 12, Persiaran Tropicana,
                                <br />
                                Tropicana Golf & Country Resort,
                                <br />
                                PJU 3, 47410 Petaling Jaya,
                                <br />
                                Selangor Darul Ehsan.
                            </p>
                        </Col>
                        <Col xs={12} xl={5}>
                            <Form onSubmit={handleSubmit} className="contact-form">
                                {status === "success" && (
                                    <Alert variant="success" className="mt-3">
                                        Your enquiry has been sent successfully!
                                    </Alert>
                                )}
                                {status === "error" && (
                                    <Alert variant="danger" className="mt-3">
                                        Failed to send your enquiry. Please try again later.
                                    </Alert>
                                )}
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label className="text-light text-uppercase fw-bolder">
                                        Name
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}  // Updated here
                                        required
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label className="text-light text-uppercase fw-bolder">
                                        Email
                                    </Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}  // Updated here
                                        required
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="phone">
                                    <Form.Label className="text-light text-uppercase fw-bolder">
                                        Phone
                                    </Form.Label>
                                    <Form.Control
                                        type="tel"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}  // Updated here
                                        required
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="message">
                                    <Form.Label className="text-light text-uppercase fw-bolder">
                                        Enquiry
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}  // Updated here
                                        rows={4}
                                        required
                                    />
                                </Form.Group>
                                <div className="d-grid d-md-inline-block gap-2">
                                    <Button
                                        className="btn-block btn-primary"
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? "Sending..." : "Submit"}
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default ContactPage;
