import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import partner1 from "../images/gs_partners_logo_elg.avif";
// import partner2 from "../images/gs_partners_logo_fn_herstal.svg";
import partner3 from "../images/gs_partners_logo_alseamar.svg";
import partner4 from "../images/gs_partners_logo_altesek.avif";

const PartnersGlobal = () => {
  const partners = [
    { img: partner1, name: "Etienne Lacroix Group" },
    { img: partner3, name: "ALSEAMAR" },
    { img: partner4, name: "ALSETEX", style: { maxWidth: "150px" } }
  ];

  return (
    <section
      className="cover-container w-100 home-our-partners noafter"
      id="partners"
    >
      <Container>
        <div className="text-container mb-5 text-center">
          <h2 className="text-light text-uppercase main-title">Our Partners</h2>
          <h3 className="text-light sub-title">
            International leaders exemplifying the zenith of innovation and excellence in defense technology.
          </h3>
        </div>
        <Row className="d-flex align-items-stretch justify-content-center">
          {partners.map((partner, index) => (
            <Col
              xs={12}
              sm={6}
              lg={3}
              className="d-flex align-items-stretch justify-content-center mb-4"
              key={index}
            >
              <div className="partners-box d-flex justify-content-center align-items-center flex-column w-100 rounded text-center p-4">
                <img
                  src={partner.img}
                  alt={partner.name}
                  className="img-fluid mb-4"
                  style={partner.style || {}}
                />
                <p className="text-uppercase text-light fw-bold mb-0">{partner.name}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default PartnersGlobal;
