import React from "react";
import { Link } from "react-router-dom";
import { Button, Row, Col, Container } from "react-bootstrap";
import logoBig from "../images/gs-logo-big.svg";

const ContactUsGlobal = () => {
  return (
    <section
      className="justify-content-center align-items-center d-flex cover-container w-100 home-contact-us"
      id="contact"
    >
      <Container>
        <div className="text-container m-auto rounded text-center">
          <div className="text-wrap">
            <Row className="align-items-center justify-content-center">
              <Col xs={12} lg={7}>
                <div className="py-5">
                  <h2 className="text-light text-uppercase main-title mb-4">
                    Let&apos;s Shape the <br className="d-none d-lg-block" />World Tomorrow
                  </h2>
                  <div className="btn-container">
                    <Link to="/contact">
                      <Button variant="secondary">
                        Contact Us <i className="icon" data-feather="arrow-right"></i>
                      </Button>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col xs={12} lg={3} className="d-none d-lg-flex align-items-stretch">
                <img src={logoBig} alt="Grey Solutions Logo" width="100%" />
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ContactUsGlobal;
