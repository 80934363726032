import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-bootstrap';
import imgAboutCompany from '../images/bg-about-01.avif';
import iconComprehensive from '../images/icon-comprehensive.svg';
import iconCustomization from '../images/icon-customization.svg';
import iconPerformance from '../images/icon-performance.svg';
import iconPartnership from '../images/icon-partnership.svg';
import PartnersGlobal from "../components/PartnersGlobal";
import ContactUsGlobal from "../components/ContactUsGlobal";

const About = () => {
    return (
        <>
            <Helmet>
                <title>Grey Solutions | About</title>
                <meta name="description" content="Grey Solutions | Advanced solutions for a safer tomorrow" />
                <meta property="og:title" content="Grey Solutions | About" />
                <meta property="og:description" content="Grey Solutions is a leading defense technology company specializing in innovative solutions. With a focus on collaboration, customer satisfaction, and sustainability, we offer a wide range of advanced systems, including weapons, UAVs, cybersecurity, and communication equipment. Our goal is to enhance military capabilities and provide a competitive edge in the global security landscape while maintaining ethical and responsible operations." />
                <meta property="og:image" itemprop="image" content="https://greysolutions.my/static/media/GS-logo.3ddecfd01f51aa7d379334435510e548.svg" />
                <meta property="og:url" content="https://greysolutions.my/about" />
            </Helmet>
            <section className="d-flex align-items-center justify-content-start cover-container w-100 about-hero-banner vh-100 section-start">
                <Container className="text-container w-100">
                    <div className="text-wrapper">
                        <h2 className="text-light text-uppercase main-title mb-4">About us</h2>
                        <h4 className="text-light mb-4 sub-title fw-normal">
                            “Led by a team of experienced executives and industry experts, the company places a strong emphasis on collaboration, teamwork, and customer satisfaction”
                        </h4>
                    </div>
                </Container>
            </section>

            <section className="d-flex justify-content-center cover-container w-100 about-the-company" id="aboutthecompany">
                <Container className="text-container m-auto">
                    <Row className="align-items-center">
                        <Col xs={12} lg={6}>
                            <h2 className="text-light text-uppercase main-title mb-4 mb-md-5">About The Company</h2>
                            <p className="text-light mb-4 copy-larger">
                                Grey Solutions is a leading defense technology company specializing in innovative solutions. With a focus on collaboration, customer satisfaction, and sustainability, we offer a wide range of advanced systems, including weapons, UAVs, cybersecurity, and communication equipment. Our goal is to enhance military capabilities and provide a competitive edge in the global security landscape while maintaining ethical and responsible operations.
                            </p>
                        </Col>
                        <Col xs={12} lg={6}>
                            <img alt="About The Company" className="img-fluid" src={imgAboutCompany} title="About The Company" />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="d-flex justify-content-center cover-container w-100 about-our-vision">
                <Container className="text-container m-auto text-center">
                    <div className="text-wrap">
                        <h2 className="text-light text-uppercase mb-4 main-title">Our Vision</h2>
                        <p className="text-light copy-larger">
                            "Our vision is to be the leading defence company in the world, dedicated to providing innovative and reliable defence solutions that protect and safeguard our clients from all threats. We aim to achieve this by leveraging cutting-edge technology, deep industry expertise, and a relentless commitment to excellence in everything we do."
                        </p>
                    </div>
                </Container>
            </section>

            <section className="d-flex justify-content-center cover-container w-100 about-our-standard" id="standards">
                <Container className="text-container m-auto text-center">
                    <div className="text-wrap">
                        <h2 className="text-light text-uppercase main-title mb-4 mb-md-5">Setting the Standards</h2>
                        <p className="text-light copy-larger">
                            As a dedicated defense company, we uphold the highest standards and embody a compelling value proposition that sets us apart in the industry. Our commitment to excellence and unwavering dedication to our customers, employees, and stakeholders form the foundation of our identity.
                        </p>
                    </div>
                </Container>
            </section>

            <section className="about-our-products cover-container w-100">
                <Container className="text-container">
                    <Row className="gap-3 justify-content-center">
                        <Col xs={12} lg={8} xl={5} className="p-4">
                            <div className="img-wrapper text-center mb-4">
                                <img src={iconComprehensive} alt="Defense solutions tailored to your needs" title="Defense solutions tailored to your needs" />
                            </div>
                            <div className="text-wrap text-center">
                                <h2 className="text-light text-uppercase sub-title">Comprehensive defense solutions</h2>
                                <p className="text-light copy-larger">
                                    We offer a comprehensive range of defense solutions tailored to meet the unique requirements of our clients. From advanced military hardware to sophisticated software systems, we provide end-to-end solutions that enhance defence capabilities.
                                </p>
                            </div>
                        </Col>
                        <Col xs={12} lg={8} xl={5} className="p-4">
                            <div className="img-wrapper text-center mb-4">
                                <img src={iconCustomization} alt="Made-to-measure solutions" title="Made-to-measure solutions" />
                            </div>
                            <div className="text-wrap text-center">
                                <h2 className="text-light text-uppercase sub-title">Customisation and Flexibility</h2>
                                <p className="text-light copy-larger">
                                    We understand that different defence organisations have distinct needs. Therefore, we prioritise customisation and flexibility in our offerings. Our team collaborates closely with clients to understand their specific requirements and deliver tailored solutions that align with their objectives.
                                </p>
                            </div>
                        </Col>
                        <Col xs={12} lg={8} xl={5} className="p-4">
                            <div className="img-wrapper text-center mb-4">
                                <img src={iconPerformance} alt="Defense solutions tailored to your needs" title="Defense solutions tailored to your needs" />
                            </div>
                            <div className="text-wrap text-center">
                                <h2 className="text-light text-uppercase sub-title">Exceptional Performance</h2>
                                <p className="text-light copy-larger">
                                    Our products and services are designed to deliver exceptional performance in terms of accuracy, reliability, and efficiency. We leverage state-of-the-art technologies and expertise to ensure our solutions exceed expectations and provide a competitive edge on the battlefield.
                                </p>
                            </div>
                        </Col>
                        <Col xs={12} lg={8} xl={5} className="p-4">
                            <div className="img-wrapper text-center mb-4">
                                <img src={iconPartnership} alt="Defense solutions tailored to your needs" title="Defense solutions tailored to your needs" />
                            </div>
                            <div className="text-wrap text-center">
                                <h2 className="text-light text-uppercase sub-title">Long-term Partnership</h2>
                                <p className="text-light copy-larger">
                                    We aim to build long-term partnerships with our clients based on trust, mutual respect, and shared success. We provide ongoing support, maintenance, and training to ensure our solutions continue to perform optimally throughout their lifecycle.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <PartnersGlobal />
            <ContactUsGlobal />
        </>
    );
};

export default About;